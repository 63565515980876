@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter';
}

strong {
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-bold {
  font-weight: 500 !important;
}

.fontMedium {
  font-weight: 500;
}

.fontBold {
  font-weight: 600 !important;
}

.fontExtraBold {
  font-weight: 800;
}

.topicTitle {
  font-family: "Inter"
}

#loading {
  animation: loading 0.6s linear infinite;
}

img {
  display: inline-block;
  max-width: unset !important;
}

.regularFont {
  font-weight: 400;
}

.MuiCollapse-root {
  border: 0 !important
}

::-webkit-scrollbar {
  display: none;
  width: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #F2F4F8;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5);
}


#nextLoading {
  animation: slide 2s ease infinite;
}


@keyframes slide {
  0% {
    transform: translateY(4px);
  }

  50% {
    transform: translateY(6px);
  }

  100% {
    transform: translateY(4px);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  100% {
    transform: rotate(360deg);
    transform-origin: 50% 50%;
  }
}

.katex * {
  font-family: 'Inter';
  font-weight: 500
}

a {
  -webkit-tap-highlight-color: transparent;
  outline-offset: 0;
}