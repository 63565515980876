.App {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  //background: no-repeat top center/150% url('./assets/bg.png');
  @media screen and (max-width: 768px) {
    background: #fff;
  }
}

.container{
  display: flex;
  flex-wrap: nowrap;
}
.content{
  width:1200px;
  @media screen and (max-width: 1200px) {
    width: 100% !important;
    padding: 0 1rem;
  }
}

.text-extra-bold{
  font-family: 'Roboto Bold';
}

.evenBg{
  background: #fff;
}

.oddBg{
  background: #F5F8FE;
}

.divider{
  height: 1px;
  background: rgba(0,0,0,0.12)
}

.footer{
  @media screen and (max-width: 768px) {
    -webkit-padding-bottom: 48px;
  }
}

#colorbgcanvas{
   border-radius: 20px;
}






.card-bg{
  background: no-repeat url('./assets/layout/card-bg.svg') top right;
  background-color: #111;
  border: 1px solid #111;
  transition: box-shadow 0.2s ease-in-out;
  &:hover{
    border: 1px solid #58FFDE;
    box-shadow: 0px 0px 6px 0px #58FFDE, 0px 4px 34.2px 0px rgba(0, 0, 0, 0.25);
  }
}

.child{
  // display: none;
  transform: scaleY(0);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.select-icon{
  transform: scaleY(-1);
  transition: transform 0.3s ease-in-out;
}

.item-box{
  border-radius: 40px;
  padding: 4px 16px;
}
.parent{
  &:hover{
    .select-icon{
      transform: scaleY(1);
    }
    .item-box{
      background: #F7F7F6;
    }
    .child{
      transform: scaleY(1);
      opacity: 1;
      // display: block;
      
    }
    
  }
}